import React from "react"
import createClass from "create-react-class"

import Contact from "../../components/info/Contact"
import { isNull } from "lodash"
import Img from "gatsby-image"
import JaSVG from "../components/JaSVG.js"
import { TweenMax, gsap } from "gsap"
import ease from "../constants/ease"
import styled, { css } from "styled-components/macro"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import pattern from "../../assets/svg/contact-pattern.svg"
import lowTri from "../../assets/svg/contact-low-triangles.svg"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const Information = props => <InformationDisplay {...props} />

export default Information

const InformationDisplay = createClass({
  componentDidMount() {
    this.animate()
    window.addEventListener("resize", this.onResize, false)
  },

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  },

  animate() {
    if (isNull(this.infoBg)) return
    const vw = window.innerWidth || document.body.clientWidth
    const xDes = vw > 900 ? "-50%" : "50%"
    const scaleY = vw > 900 ? 1 : -1
    const tl = gsap.timeline({ delay: 0.5 })
    TweenMax.set(this.infoBg, {
      css: { scaleY },
    })
    tl.from(
      this.infoBg,
      {
        duration: 0.75,
        alpha: 0,
        ease: ease("outQuad"),
      },
      "start"
    )
    tl.from(
      this.infoBg,
      {
        duration: 0.75,
        x: xDes,
        ease: "power3.out",
      },
      "start"
    )
    tl.from(
      this.txt,
      {
        duration: 0.75,
        x: "-50%",
        ease: "power3.out",
      },
      "start"
    )
    tl.from(
      this.txt,
      {
        duration: 0.75,
        alpha: 0,
        ease: ease("outQuad"),
      },
      "start"
    )
  },

  onResize() {
    TweenMax.set([this.infoBg, this.txt], { clearProps: "transform" })
    window.removeEventListener("resize", this.onResize)
  },

  renderImage({ fluid, ...item }, i) {
    return <Img key={"info" + i} className="about-bg-img" fluid={fluid} />
  },

  render() {
    const { bioRich, images, message, ...rest } = this.props
    const contacts = [{ ...rest }]

    return (
      <div className="page information">
        <div className="contact-content fade-in">
          <div className="contact-bg" />
          <div>
            <div
              ref={i => (this.infoBg = i)}
              className="contact-bg-img"
              style={{ backgroundImage: `url(${pattern})` }}
            />
          </div>
          <div className="contact-container">
            <div className="contact">
              <div className="txt">
                <div ref={i => (this.txt = i)}>
                  {contacts &&
                    contacts.map((item, i) => (
                      <Contact key={"c" + i} {...item} isFirst={i === 0} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {bioRich?.json && (
          <Bio className="bio">
            {documentToReactComponents(bioRich.json, options)}
          </Bio>
        )}

        <div className="contact-images">
          <div className="bg-strip" />
          <div className="c-image-container">
            <div className="info-img-flex">
              {images && images.map(this.renderImage)}
            </div>
          </div>
        </div>

        <div className="contact-message">
          <div>{message}</div>
        </div>

        <ContactLow className="contact-low" src={lowTri}>
          <div className="ja-red">
            <JaSVG fill="#7D0102" />
          </div>
        </ContactLow>
      </div>
    )
  },
})

const ContactLow = styled.div(
  ({ src }) => css`
    background: #c82434 url("${src}") left bottom no-repeat;
    background-position: contain;
    `
)

const Bio = styled.div`
  a {
    text-transform: none !important;
    font-family: "DinMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    transform: translate3d(0, 0, 0);
    outline: none;
    -webkit-tap-highlight-color: transparent;

    a:link,
    a:focus,
    a:visited {
      position: relative;
      display: block;
      color: #404041;
      z-index: 3;
      transition: color 300ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
      cursor: pointer;
    }

    &:hover {
      color: #404041;
      z-index: 3;
      transition: color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:hover:after {
      height: 50%;
      transition: height 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    a:active:after {
      height: 0.1rem;
      transition: height 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:after {
      position: absolute;
      display: block;
      bottom: 0;
      left: -2%;
      background: #4fbbeb;
      height: 0rem;
      width: 104%;
      content: "";
      transition: height 300ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
      z-index: -1 !important;
    }
  }
`
