import React from 'react'

const JaSVG = ({ fill }) =>
  <svg className="ja-svg" viewBox="0 0 32 23">
    <g stroke="none" fill="none" fillRule="evenodd">
      <path
        className="ja-shape"
        d="M18 0L18 0 9.1 15.2 0.1 15.2 4.6 22.9 13.5 22.9 18 15.3 22.5 22.9 31.4 22.9 18 0Z"
        fill={fill}
      />
    </g>
  </svg>

export default JaSVG
