import React, { useEffect, useRef } from "react"
import Social from "../components/Social"
import { TweenMax } from "gsap"

export default function Contact({ isFirst, email, phone, title, name }) {
  const titleRef = useRef()
  const nameRef = useRef()
  const emailRef = useRef()
  const phoneRef = useRef()
  const socialRef = useRef()

  useEffect(() => {
    TweenMax.to(
      [titleRef.current, nameRef.current, emailRef.current, phoneRef.current],
      {
        duration: 0.5,
        y: 0,
        opacity: 1,
        ease: "power3.out",
        stagger: 0.05,
        delay: 0.5,
      }
    )
  }, [])

  return (
    <div className="c-item c-ja">
      <div className="c-label c-hide">
        <div ref={titleRef}>{title}</div>
      </div>
      <div className="c-title c-hide">
        <div ref={nameRef}>{name}</div>
      </div>
      <div className="txt-link c-hide">
        <div ref={emailRef}>
          <a className="c-lo" href={"mailto:" + email}>
            {email}
          </a>
        </div>
      </div>
      <div className="txt-link c-hide">
        <div ref={phoneRef}>
          <a href={"tel:" + phone}>{phone}</a>
        </div>
      </div>
      {isFirst && (
        <div ref={socialRef} className="social-container">
          <Social />
        </div>
      )}
    </div>
  )
}
