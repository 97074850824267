import React, { useEffect } from "react"
import { graphql } from "gatsby"

import SEO from "../components/SeoMeta"
import Information from "../components/pages/Information"
import NavSpacer from "../components/common/NavSpacer"

import { useAppContext } from "../context/AppContext"

const InformationPage = ({ data, ...props }) => {
  const { setLogoColored } = useAppContext()
  const info = data.contentfulInformation
  const { siteMetadata } = data.site
  const seoImage = info.featuredImage?.fixed

  useEffect(() => {
    setLogoColored()
  }, [])

  useEffect(() => {
    console.log("mount")
  }, [])

  return (
    <>
      <SEO {...siteMetadata} image={seoImage} title="Information" />
      <NavSpacer />
      <Information {...info} />
    </>
  )
}

export const query = graphql`
  query InformationPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulInformation {
      bio {
        bio
      }
      bioRich {
        json
      }
      email
      name
      message
      phone
      title
      featuredImage {
        fixed(width: 1200, height: 630) {
          src
          height
          width
        }
      }
      images {
        id
        title
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        fluid(maxWidth: 1200) {
          srcWebp
          srcSetWebp
          src
          sizes
          srcSet
          aspectRatio
        }
      }
    }
  }
`

export default InformationPage
